import { CSSProperties } from 'react'

export const layoutStyles: CSSProperties = {
    backgroundImage: 'url(/background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}

export const titleStyles: CSSProperties = {
    textAlign: 'center',
    fontSize: '24px',
    letterSpacing: '-0.04em',
    marginBottom: '24px',
    overflowWrap: 'break-word',
    hyphens: 'manual',
    textOverflow: 'unset',
    whiteSpace: 'pre-wrap',
}
