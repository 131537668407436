import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import { GetDeliveryOptionsDocument, GetDeliveryOptionDocument } from '../../gql/graphql'

export const DeliveryOptionsDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetDeliveryOptionsDocument, {
                skip: paging.skip,
                limit: paging.limit,
            })
            return {
                data: result.adminDeliveryOptions.items,
                total: result.adminDeliveryOptions.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetDeliveryOptionDocument, { id })
            return { data: result.adminDeliveryOption }
        },
        // async update({ variables, id }: { variables: UpdateDeliveryOptionInput; id: string }) {
        //     const { name, icon, parentId } = variables
        //     const sortIndex = variables.sortIndex ? Number(variables.sortIndex) : variables.sortIndex
        //     const updateDeliveryOptionInput = {
        //         name: name,
        //         icon: icon,
        //         sortIndex: sortIndex,
        //         parentId: parentId,
        //     }

        //     const result = await client.request(UpdateDeliveryOptionDocument, { id, updateDeliveryOptionInput })
        //     return {
        //         data: result.adminDeliveryOptionUpdate,
        //     }
        // },
    } as DataProvider)
