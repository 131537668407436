import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import {
    GetBannersDocument,
    GetBannerDocument,
    UpdateBannerInput,
    UpdateBannerDocument,
    CreateBannerInput,
    CreateBannerDocument,
} from '../../gql/graphql'
import { DateTime } from 'luxon'
// import { UpdateBannerInput } from 'gql/graphql'

export const BannersDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetBannersDocument, { skip: paging.skip, limit: paging.limit })
            return {
                data: result.adminBanners.items,
                total: result.adminBanners.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetBannerDocument, { id })
            return { data: result.adminBanner }
        },
        async update({ variables, id }: { variables: UpdateBannerInput; id: string }) {
            const { sortIndex, startDate, showButton, images: imageInput, endDate, ...input } = variables
            const images = imageInput?.filter((image) => image.file)
            const result = await client.request(UpdateBannerDocument, {
                id,
                input: {
                    ...(sortIndex && { sortIndex: Number(sortIndex) }),
                    ...(images && images.length > 0 && { images }),
                    showButton: showButton ?? false,
                    startDate: DateTime.fromISO(startDate).toISO(),
                    ...(endDate && endDate !== '' && { endDate }),
                    ...input,
                },
            })
            return {
                data: result.adminBannerUpdate,
            }
        },
        async create({ variables }: { variables: CreateBannerInput }) {
            const { sortIndex, startDate, images: imageInput, showButton, endDate, ...input } = variables
            const images = imageInput?.filter((image) => image.file)
            try {
                const result = await client.request(CreateBannerDocument, {
                    input: {
                        ...(sortIndex && { sortIndex: Number(sortIndex) }),
                        ...(images && images.length > 0 && { images }),
                        showButton: showButton ?? false,
                        startDate: DateTime.fromISO(startDate).toISO(),
                        ...(endDate && endDate !== '' && { endDate }),
                        ...input,
                    },
                })
                return {
                    data: result.adminBannerCreate,
                }
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    } as DataProvider)
