import { HttpError, useList } from '@pankod/refine-core'
import { Edit, Box, TextField, Autocomplete, Icon } from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { Category, UpdateCategoryInput } from 'gql/graphql'
import { materialIconNames } from './iconNames'

export const CategoryEdit: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
        control,
    } = useForm<Category, HttpError, UpdateCategoryInput>({
        refineCoreProps: {
            dataProviderName: 'categories',
        },
    })

    const { data: parentsData } = useList<Category>({
        resource: 'categories',
        config: {
            filters: [{ field: 'parentOnly', operator: 'eq', value: true }],
        },
    })

    const parentOptions = parentsData?.data.map((parent) => {
        return { value: parent.id, label: parent.name.en }
    })

    const sortIndexHelperText = 'The smaller the index the higher the priority.'

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <TextField
                    error={!!errors?.name?.en}
                    helperText={errors.name?.en?.message}
                    {...register('name.en', { required: 'Name is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="name.en"
                    label="Name in English"
                    name="name.en"
                    defaultValue=" "
                />
                <TextField
                    error={!!errors?.name?.zhHant}
                    helperText={errors.name?.zhHant?.message}
                    {...register('name.zhHant', { required: 'Name is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="name.zhHant"
                    label="Name in Traditional Chinese"
                    name="name.zhHant"
                    defaultValue=" "
                />
                <TextField
                    error={!!errors?.sortIndex}
                    helperText={errors.sortIndex?.message ? errors.sortIndex?.message : sortIndexHelperText}
                    {...register('sortIndex')}
                    margin="normal"
                    fullWidth
                    id="sortIndex"
                    label="Sort Index"
                    name="sortIndex"
                    defaultValue=" "
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />
                <Controller
                    control={control}
                    name="icon"
                    rules={{ required: 'Icon is required' }}
                    defaultValue={""}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            options={materialIconNames}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="icon"
                                    label="Icon"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.icon}
                                    helperText={errors.icon?.message}
                                    required
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Icon>{option}</Icon>
                                </li>
                            )}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="parentId"
                    defaultValue={queryResult?.data?.data.parent?.id}
                    render={({ field: { onChange, value, ...props } }) => (
                        <Autocomplete
                            {...props}
                            onChange={(_, value) => {
                                onChange(value?.value)
                            }}
                            value={parentOptions?.find((p) => p?.value === value)}
                            options={parentOptions ? parentOptions : []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="parentId"
                                    label="Parent"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.parentId}
                                    helperText={errors.parentId?.message}
                                />
                            )}
                        />
                    )}
                />
            </Box>
        </Edit>
    )
}
