import { HttpError } from '@pankod/refine-core'
import { Edit, Box, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { Config, UpdateConfigInput } from 'gql/graphql'

export const ConfigEdit: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
    } = useForm<Config, HttpError, UpdateConfigInput>({
        refineCoreProps: {
            dataProviderName: 'configs',
        },
    })
    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="value"
                    defaultValue=" "
                    helperText={queryResult?.data?.data?.description}
                    label={queryResult?.data?.data?.name ?? 'Value'}
                />
            </Box>
        </Edit>
    )
}
