import { Category } from 'gql/graphql'
import { GetOneResponse, QueryObserverResult, useList } from '@pankod/refine-core'
import { Box, Icon, FormLabel, Typography, Checkbox } from '@pankod/refine-mui'
import { Controller } from '@pankod/refine-react-hook-form'
import { Shop } from 'gql/graphql'
import { useEffect } from 'react'

export const ServicesSection = ({
    formLoading,
    queryResult,
    control,
    reset,
    getValues,
}: {
    formLoading: boolean
    queryResult: QueryObserverResult<GetOneResponse<Shop>, unknown> | undefined
    control: any
    reset: any
    getValues: any
}) => {
    const { data: categories, isLoading } = useList<Category>({
        resource: 'categories',
        dataProviderName: 'categories',
        config: {
            filters: [
                {
                    field: 'parentOnly',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    })

    useEffect(() => {
        setTimeout(() => {
            if (queryResult?.data) {
                const { data } = queryResult.data
                const { categories } = data
                const values = getValues()
                const newValues = {
                    ...values,
                    categoryIds: {
                        ...categories?.reduce((acc: any, category: Category) => {
                            acc[category.id] = true
                            return acc
                        }, {}),
                    },
                }
                reset(newValues)
            }
        }, 500)
    }, [formLoading, categories, isLoading])

    return (
        <>
            {categories &&
                categories.data.map((category, key) => (
                    <Box
                        key={key}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Controller
                            name={`categoryIds[${category.id}]`}
                            control={control}
                            defaultValue={false}
                            render={({ field: { onChange, value, ...rest } }) => (
                                <FormLabel
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        py: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                        }}
                                        color="primary"
                                    >
                                        <Icon
                                            fontSize="small"
                                            sx={{
                                                color: 'gold.main',
                                            }}
                                        >
                                            {category.icon ?? 'store'}
                                        </Icon>
                                        <Typography>{category.name.en}</Typography>
                                    </Typography>
                                    <Checkbox
                                        {...rest}
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e)
                                        }}
                                        // value={deliveryOption.id}
                                        color="secondary"
                                    />
                                </FormLabel>
                            )}
                        />
                        {category.children && category.children?.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderLeft: 2,
                                    borderColor: 'rose.main',
                                    pl: 3,
                                    gap: 4,
                                    py: 1,
                                }}
                            >
                                {category.children.map((child, key) => (
                                    <Controller
                                        key={key}
                                        name={`categoryIds[${child.id}]]`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value, ...rest } }) => (
                                            <FormLabel
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography color="primary">
                                                    <Typography>{child.name.en}</Typography>
                                                </Typography>
                                                <Checkbox
                                                    {...rest}
                                                    checked={value}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                    }}
                                                    // value={deliveryOption.id}
                                                    color="secondary"
                                                />
                                            </FormLabel>
                                        )}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                ))}
        </>
    )
}
