import { forwardRef } from 'react'
import { Icon as IC, SvgIcon } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Icon = forwardRef(
    ({ className = '', fontWeight, fontSize, children = '', sx: csx, ...props }: any, ref) => {
        const isFontAwesome = typeof children === 'string' && children.includes('fa-')
        const baseClassName = isFontAwesome ? 'fa' : undefined
        const customClassName = `${className}`
        const sx = {
            ...csx,
            ...(fontSize &&
                fontSize === 'small' && {
                    fontSize: '1rem !important',
                }),
            ...(fontWeight && {
                fontWeight: `${fontWeight} !important`,
            }),
        }

        if (typeof children === 'string') {
            switch (children) {
                case 'home':
                    return (
                        <SvgIcon ref={ref} sx={sx} viewBox="0 0 12 12" className={customClassName} {...props}>
                            <path d="M2.00008 10.6667H4.00008V6.66667H8.00008V10.6667H10.0001V4.66667L6.00008 1.66667L2.00008 4.66667V10.6667ZM0.666748 12V4L6.00008 0L11.3334 4V12H6.66675V8H5.33342V12H0.666748Z" />
                        </SvgIcon>
                    )
                case 'facebook':
                    return (
                        <IC
                            ref={ref}
                            sx={sx}
                            baseClassName={'fa'}
                            className={`fa-brands fa-facebook-f ${customClassName}`}
                            {...props}
                        />
                    )
                case 'instagram':
                    return (
                        <IC
                            ref={ref}
                            sx={sx}
                            baseClassName={'fa'}
                            className={`fa-brands fa-instagram ${customClassName}`}
                            {...props}
                        />
                    )
                case 'whatsapp':
                    return (
                        <IC
                            ref={ref}
                            sx={sx}
                            baseClassName={'fa'}
                            className={`fa-brands fa-whatsapp ${customClassName}`}
                            {...props}
                        />
                    )
            }
        }

        return (
            <IC ref={ref} baseClassName={baseClassName} sx={sx} className={customClassName} {...props}>
                {children}
            </IC>
        )
    },
)

Icon.displayName = 'Icon'
