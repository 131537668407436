import { HttpError } from '@pankod/refine-core'
import { Box, TextField } from '@pankod/refine-mui'
import { UseFormReturnType } from '@pankod/refine-react-hook-form'
import { AdminBlog, CreateBlogInput, UpdateBlogInput } from 'gql/graphql'
import { BlogSectionFrom } from './section'

export const BlogContentFrom = ({
    language,
    form,
    isEdit = false,
}: {
    language: 'en' | 'zhHant'
    form: any
    isEdit?: boolean
}) => {
    const {
        refineCore: { formLoading, queryResult },
        register,
        formState: { errors },
    } = form as unknown as UseFormReturnType<AdminBlog, HttpError, CreateBlogInput | UpdateBlogInput>
    const index = language === 'en' ? 0 : 1
    return (
        <Box>
            <input type="hidden" {...register(`blogContents.${index}.language`)} value={language} />
            <TextField
                error={!!errors?.blogContents?.[index]?.title}
                helperText={errors?.blogContents?.[index]?.title?.message}
                {...register(`blogContents.${index}.title`, { required: 'Title is required' })}
                margin="normal"
                required
                fullWidth
                id={`blogContents.${index}.title`}
                label="Title"
                name={`blogContents.${index}.title`}
                InputLabelProps={{ shrink: true }}
                defaultValue={isEdit ? ' ' : ''}
            />
            <TextField
                error={!!errors?.blogContents?.[index]?.description}
                helperText={errors?.blogContents?.[index]?.description?.message}
                {...register(`blogContents.${index}.description`)}
                margin="normal"
                fullWidth
                id={`blogContents.${index}.description`}
                label="Description"
                name={`blogContents.${index}.description`}
                InputLabelProps={{ shrink: true }}
                defaultValue={isEdit ? ' ' : ''}
            />
            <BlogSectionFrom language={language} form={form} />
        </Box>
    )
}
