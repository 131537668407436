import { GetOneResponse, QueryObserverResult } from '@pankod/refine-core'
import { Box, Button, Typography } from '@pankod/refine-mui'
import { Controller } from '@pankod/refine-react-hook-form'
import { BACKEND_URL } from 'config'
import { Shop, ShopImageType } from 'gql/graphql'
import { useEffect, useState } from 'react'

export interface FeatureImageInputProps {
    control: any
    formLoading: boolean
    queryResult: QueryObserverResult<GetOneResponse<Shop>, unknown> | undefined
}

export const FeatureImageInput = ({ control, queryResult, formLoading }: FeatureImageInputProps) => {
    const [featureImage, setFeatureImage] = useState<string | undefined>(undefined)
    const [id, setId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (queryResult?.data?.data?.images) {
            const featureImageImage = queryResult.data.data.images.find((image) => image.type === ShopImageType.Feature)
            if (featureImageImage) {
                setFeatureImage(`${BACKEND_URL}${featureImageImage.url}`)
                setId(featureImageImage.id)
            }
        }
    }, [formLoading])

    return (
        <>
            <Typography variant="h6">Feature Image</Typography>
            {featureImage && (
                <img
                    src={featureImage}
                    alt="featureImage"
                    style={{ width: '200px', height: 'auto', marginBottom: 10 }}
                />
            )}
            <Box display="flex" flexDirection="row" gap={2}>
                {' '}
                <Controller
                    control={control}
                    name="featureImage"
                    defaultValue={null as any}
                    render={({ field }) => {
                        const { value, onChange, ...rest } = field
                        return (
                            <Button variant="contained" component="label" fullWidth style={{ marginBottom: 10 }}>
                                {featureImage ? 'Upload another image' : 'Upload Feature Image'}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            // Check file only support 2mb
                                            if (e.target.files[0].size > 2000000) {
                                                alert('File size must be less than 2mb')
                                                return
                                            }
                                            onChange(e.target.files[0])
                                            const objectUrl = URL.createObjectURL(e.target.files[0])
                                            setFeatureImage(objectUrl)
                                        }
                                    }}
                                    {...rest}
                                />
                            </Button>
                        )
                    }}
                />
                {featureImage && (
                    <Controller
                        control={control}
                        name={`removeImages.${id}`}
                        defaultValue={false}
                        render={({ field }) => {
                            const { value, onChange, ...rest } = field
                            return (
                                <Button
                                    variant="contained"
                                    color="error"
                                    fullWidth
                                    style={{ marginBottom: 10 }}
                                    onClick={() => {
                                        onChange(true)
                                        setFeatureImage(undefined)
                                    }}
                                >
                                    Remove Feature Image
                                </Button>
                            )
                        }}
                    />
                )}
            </Box>
        </>
    )
}
