import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import {
    GetShopsDocument,
    GetShopDocument,
    ShopDeliveryOptionInput,
    UpdateShopDocument,
    AuthenticationType,
    SocialMedia,
    ShopSocialMedia,
    Store,
    Category,
    AdminCreateShopInput,
    CreateShopDocument,
    UploadImagesDocument,
    ShopImageType,
    DeleteImagesDocument,
} from '../../gql/graphql'
import { AdminUpdateShopInput } from 'gql/graphql'

type CustomInput = {
    deliveryOptions: {
        [key: string]: {
            selected: boolean
            additionalInfo: string
        }
    }
    serviceProviders: {
        [key: string]: {
            selected: boolean
            additionalInfo: string
            deliveryOptionId: string
        }
    }
    featureImage: File
    normalImages: File[]
    logoImage: File
    removeImages: {
        [key: string]: boolean
    }
    categoryIds: {
        [key: string]: boolean
    }
}

export type ShopEditInput = AdminUpdateShopInput & CustomInput
export type ShopCreateInput = AdminCreateShopInput & CustomInput

export const ShopsDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetShopsDocument, { skip: paging.skip, limit: paging.limit })
            return {
                data: result.adminShops.items,
                total: result.adminShops.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetShopDocument, { id })
            return { data: result.adminShop }
        },
        async update({ variables, id }: { variables: ShopEditInput; id: string }) {
            const {
                deliveryOptions,
                serviceProviders,
                authentications,
                categoryIds,
                normalImages,
                featureImage,
                logoImage,
                removeImages,
                stores: storesInput,
                ...updateShopInput
            } = variables
            console.log('normalImages', normalImages)
            const shopsDeliveryOptions: ShopDeliveryOptionInput[] = deliveryOptions
                ? Object.keys(deliveryOptions)
                      .filter((key) => deliveryOptions[key].selected)
                      .map((key) => ({
                          deliveryOptionId: key,
                          deliveryServiceProviderId: null,
                          customInfo: deliveryOptions[key].additionalInfo || null,
                      }))
                : []
            const shopServiceProviders: ShopDeliveryOptionInput[] = serviceProviders
                ? Object.keys(serviceProviders)
                      .filter((key) => serviceProviders[key].selected)
                      .map((key) => ({
                          deliveryOptionId: serviceProviders[key].deliveryOptionId,
                          deliveryServiceProviderId: key,
                          customInfo: serviceProviders[key].additionalInfo || null,
                      }))
                : []
            const stores = storesInput?.filter((store) => store.longitude && store.latitude)
            const shopDeliveryOptionInput = [...shopsDeliveryOptions, ...shopServiceProviders]
            const input: AdminUpdateShopInput = {
                ...updateShopInput,
                authentications: authentications?.map((auth) => (auth as unknown as AuthenticationType).id),
                categories: Object.keys(categoryIds).filter((key) => categoryIds[key]),
                socialMedias: updateShopInput.socialMedias?.map((social) => {
                    const socialMedia: ShopSocialMedia = social as unknown as ShopSocialMedia
                    return {
                        id: socialMedia.id,
                        link: socialMedia.link,
                        socialMediaId: socialMedia.socialMedia.id,
                    }
                }),
                deliveryOptions: shopDeliveryOptionInput,
                ...(stores &&
                    !updateShopInput.isOnline &&
                    stores.length > 0 && {
                        stores: stores.map((store) => {
                            const { createdAt, updatedAt, latitude, longitude, ...rest } = store as unknown as Store
                            return {
                                ...rest,
                                latitude: parseFloat(latitude.toString()),
                                longitude: parseFloat(longitude.toString()),
                            }
                        }),
                    }),
            }
            console.log(input)
            const result = await client.request(UpdateShopDocument, {
                id,
                input,
            })

            await uploadShopImages(id, client, featureImage, normalImages, logoImage, removeImages)
            return {
                data: result.adminShopUpdate,
            }
        },
        async create({ variables }: { variables: ShopCreateInput }) {
            const {
                deliveryOptions,
                serviceProviders,
                authentications,
                categoryIds,
                featureImage,
                normalImages,
                logoImage,
                stores: storesInput,
                removeImages,
                ...createShopInput
            } = variables
            const shopsDeliveryOptions: ShopDeliveryOptionInput[] = deliveryOptions
                ? Object.keys(deliveryOptions)
                      .filter((key) => deliveryOptions[key].selected)
                      .map((key) => ({
                          deliveryOptionId: key,
                          deliveryServiceProviderId: null,
                          customInfo: deliveryOptions[key].additionalInfo || null,
                      }))
                : []
            const shopServiceProviders: ShopDeliveryOptionInput[] = serviceProviders
                ? Object.keys(serviceProviders)
                      .filter((key) => serviceProviders[key].selected)
                      .map((key) => ({
                          deliveryOptionId: serviceProviders[key].deliveryOptionId,
                          deliveryServiceProviderId: key,
                          customInfo: serviceProviders[key].additionalInfo || null,
                      }))
                : []

            const shopDeliveryOptionInput = [...shopsDeliveryOptions, ...shopServiceProviders]
            const stores = storesInput?.filter((store) => store.longitude && store.latitude)
            const input: AdminCreateShopInput = {
                ...createShopInput,
                authentications: authentications?.map((auth) => (auth as unknown as AuthenticationType).id),
                categories: Object.keys(categoryIds).filter((key) => categoryIds[key]),
                socialMedias: createShopInput.socialMedias?.map((social) => {
                    const socialMedia: ShopSocialMedia = social as unknown as ShopSocialMedia
                    return {
                        // id: socialMedia.id,
                        link: socialMedia.link,
                        socialMediaId: socialMedia.socialMedia.id,
                    }
                }),
                deliveryOptions: shopDeliveryOptionInput,
                ...(stores &&
                    !createShopInput.isOnline &&
                    stores.length > 0 && {
                        stores: stores.map((store) => {
                            const { createdAt, updatedAt, name, address, latitude, longitude, ...rest } =
                                store as unknown as Store
                            if (!address) {
                                throw new Error('Store Address is required')
                            }
                            return {
                                ...rest,
                                latitude: parseFloat(latitude.toString()),
                                longitude: parseFloat(longitude.toString()),
                                name,
                                address,
                            }
                        }),
                    }),
            }

            const result = await client.request(CreateShopDocument, { id: '', input })
            const shopId = result.adminShopCreate.id
            await uploadShopImages(shopId, client, featureImage, normalImages, logoImage, removeImages)
            return {
                data: result.adminShopCreate,
            }
        },
    } as DataProvider)

const uploadShopImages = async (
    shopId: string,
    client: GraphQLClient,
    featureImage?: File,
    images?: File[],
    logo?: File,
    removeImages?: {
        [key: string]: boolean
    },
) => {
    if (removeImages) {
        const ids = Object.keys(removeImages).filter((key) => removeImages[key])
        if (ids.length > 0) {
            const data = await client.request(DeleteImagesDocument, {
                shopId,
                ids,
            })
        }
    }

    if (featureImage || images || logo) {
        const imageInput = [
            ...(images
                ? (Object.keys(images)?.map((key) => ({
                      image: images?.[key as unknown as number],
                      type: ShopImageType.Gallery,
                  })) as any)
                : []),
            ...(featureImage ? [{ image: featureImage, type: ShopImageType.Feature }] : []),
            ...(logo ? [{ image: logo, type: ShopImageType.Logo }] : []),
        ]
        const data = await client.request(UploadImagesDocument, {
            shopId,
            images: imageInput,
        })
    }
}
