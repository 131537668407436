import React from 'react'
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    Stack,
    EditButton,
    List,
    TextFieldComponent,
    ShowButton,
    DeleteButton,
} from '@pankod/refine-mui'

import { SearchSuggestion } from 'gql/graphql'

const columns: GridColumns<SearchSuggestion> = [
    {
        field: 'country',
        headerName: 'Country',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.country} />
        },
    },
    {
        field: 'name.en',
        headerName: 'Name(English)',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <>
                    <TextFieldComponent value={params.row.name.en} />
                </>
            )
        },
    },
    {
        field: 'name.zhHant',
        headerName: 'Name(ZH)',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <>
                    <TextFieldComponent value={params.row.name.zhHant ?? ''} />
                </>
            )
        },
    },
    {
        field: 'enabled',
        headerName: 'Enabled',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <>
                    <TextFieldComponent value={params.row.enabled ? 'Yes' : 'No'} />
                </>
            )
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    <ShowButton hideText />
                    <EditButton hideText recordItemId={params.row.id} />
                    <DeleteButton hideText recordItemId={params.row.id} dataProviderName="searchSuggestions" />
                </Stack>
            )
        },
    },
]

export const SearchSuggestionList: React.FC = () => {
    const { dataGridProps } = useDataGrid<SearchSuggestion>({
        dataProviderName: 'searchSuggestions',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
