import { GetOneResponse, QueryObserverResult } from '@pankod/refine-core'
import { Box, Button, Typography } from '@pankod/refine-mui'
import { Controller } from '@pankod/refine-react-hook-form'
import { useEffect, useState } from 'react'

export interface ImageInputProps {
    name: string
    label: string
    existImage?: string
    control: any
    required?: boolean
    error?: any
}

export const ImageInput = ({ name, label, existImage, control, required, error }: ImageInputProps) => {
    const [image, setImage] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (existImage) {
            setImage(existImage)
        }
    }, [existImage])

    return (
        <Box
            sx={{
                marginTop: 1,
                marginBottom: 4,
            }}
        >
            <Typography>{label}</Typography>
            {image && <img src={image} alt="label" style={{ width: '200px', height: 'auto', marginBottom: 10 }} />}
            <Box display="flex" flexDirection="row" gap={2}>
                <Controller
                    control={control}
                    name={name}
                    defaultValue={null as any}
                    rules={{ required: required ? 'Image is required' : false }}
                    render={({ field }) => {
                        const { value, onChange, ...rest } = field
                        return (
                            <Button variant="contained" component="label" fullWidth style={{ marginBottom: 10 }}>
                                {image ? 'Upload another image' : 'Upload Image'}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    id={name}
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            if (e.target.files[0].size > 2000000) {
                                                alert('File size must be less than 2mb')
                                                return
                                            }
                                            onChange(e.target.files[0])
                                            const objectUrl = URL.createObjectURL(e.target.files[0])
                                            setImage(objectUrl)
                                        }
                                    }}
                                    {...rest}
                                />
                            </Button>
                        )
                    }}
                />
                {image && (
                    <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        style={{ marginBottom: 10 }}
                        onClick={() => {
                            setImage(undefined)
                            const input = document.getElementById(name) as HTMLInputElement
                            input.value = ''
                        }}
                    >
                        {`Remove ${label}`}
                    </Button>
                )}
            </Box>
            <Typography color="error">{error}</Typography>
        </Box>
    )
}
