import { TextField } from '@pankod/refine-mui'
import { BaseTextFieldProps, StandardTextFieldProps, Tab, Tabs, TextFieldProps, Typography } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { Box } from '@mui/system'
import { UseFormRegister } from '@pankod/refine-react-hook-form'

export interface MultilingualTextFieldProps {
    name: string
    errors?: any
    register: UseFormRegister<any>
}
export const langs = ['en', 'zhHant']

export const TabPanel = (props: { children?: React.ReactNode; index: string; value: string }) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}
export const MultilingualTextField = ({
    required,
    name,
    label,
    errors,
    helperText,
    defaultValue = ' ',
    register,
    variant = 'outlined',
    ...rest
}: MultilingualTextFieldProps & BaseTextFieldProps) => {
    const [selectedLang, setSelectedLang] = useState('en')
    const isError = !!(errors?.[name]?.en || errors?.[name]?.zhHant)
    return (
        <Box sx={{ width: '100%', mb: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedLang}
                    onChange={(e: SyntheticEvent, newLang: string) => {
                        setSelectedLang(newLang)
                    }}
                    aria-label="Language Input Tabs"
                    // textColor="secondary"
                    // indicatorColor="secondary"
                >
                    {langs.map((lang, key) => (
                        <Tab label={lang === 'en' ? 'English' : '中文'} value={lang} key={key} color="error" />
                    ))}
                </Tabs>
            </Box>
            {langs.map((lang, key) => (
                <TabPanel value={selectedLang} index={lang} key={key}>
                    <TextField
                        {...rest}
                        {...register(`${name}.${lang}`, {
                            required: required && lang === 'en' ? `${name}is required` : false,
                        })}
                        required={required && lang === 'en' ? true : false}
                        name={`${name}.${lang}`}
                        error={isError}
                        helperText={errors?.[name]?.[lang]?.message ?? helperText ?? ''}
                        id={`${name}.${lang}`}
                        label={`${label ?? name} in ${lang === 'en' ? 'English' : 'Traditional Chinese'}`}
                        defaultValue={defaultValue}
                        variant={variant}
                    />
                </TabPanel>
            ))}
            <Typography variant="caption" sx={{ mt: 1, color: 'error.main' }}>
                {isError && 'Please fill in all the fields'}
            </Typography>
        </Box>
    )
}
