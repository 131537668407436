import { GraphQLClient } from 'graphql-request'
import { AuthProvider } from '@pankod/refine-core'
import { isExpired, decodeToken } from 'react-jwt'

export const ACCESS_TOKEN_KEY = 'two-hands-cms-token'
export const REFRESH_TOKEN_KEY = 'two-hands-cms-refresh-token'

import { AdminLoginDocument, AdminRefreshTokenDocument, AdminMeDocument } from './gql/graphql'
import { APIError } from 'App'

export const authProvider = (client: GraphQLClient): AuthProvider => ({
    login: async ({ email, password }) => {
        if (email && password) {
            try {
                const {
                    adminLogin: { accessToken, refreshToken },
                } = await client.request(AdminLoginDocument, {
                    email,
                    password,
                })
                localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
                localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)

                return Promise.resolve()
            } catch (error) {
                return Promise.reject(new Error('Please enter a valid username and password'))
            }
        }
        return Promise.reject(new Error('Please enter a valid username and password'))
    },
    logout: () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        return Promise.resolve()
    },
    checkError: (error: APIError) => {
        if (error.statusCode === 401) {
            return Promise.reject()
        }
        return Promise.resolve()
    },
    checkAuth: async () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)

        if (accessToken && refreshToken) {
            if (isExpired(accessToken)) {
                try {
                    const {
                        adminRefreshToken: { accessToken: newAccessToken },
                    } = await client.request(AdminRefreshTokenDocument, {
                        token: refreshToken,
                    })
                    localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken)
                    client.setHeader('Authorization', `Bearer ${newAccessToken}`)
                    return Promise.resolve()
                } catch (error) {
                    return Promise.reject()
                }
            }
            if (isExpired(refreshToken)) {
                return Promise.reject()
            }
            client.setHeader('Authorization', `Bearer ${accessToken}`)
            return Promise.resolve()
        }
        return Promise.reject()
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const token = localStorage.getItem(ACCESS_TOKEN_KEY)
        if (token) {
            try {
                const { adminMe } = await client.request(AdminMeDocument)
                return Promise.resolve(adminMe)
            } catch (error: any) {
                return Promise.reject()
            }
        }

        return Promise.reject()
    },
})
