import { HttpError } from '@pankod/refine-core'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@pankod/refine-mui'
import { UseFormReturnType } from '@pankod/refine-react-hook-form'
import { Admin, AdminBlog, BlogSectionInput, BlogSectionType, CreateBlogInput, UpdateBlogInput } from 'gql/graphql'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { ShopSocialMedia } from 'gql/graphql'
import { Control, Controller, useFieldArray } from '@pankod/refine-react-hook-form'
import { Autocomplete, Button, MuiTextFieldProps, Typography, useAutocomplete } from '@pankod/refine-mui'
import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw } from 'draft-js'
import { ImageInput } from 'components/ImageInput'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import WYSIWYGEditor from 'components/WYSIWYGEditor'
export const BlogSectionFrom = ({ language, form }: { language: 'en' | 'zhHant'; form: any }) => {
    const {
        refineCore: { formLoading, queryResult },
        register,
        control,
        getValues,
        formState: { errors },
    } = form as unknown as UseFormReturnType<AdminBlog, HttpError, CreateBlogInput | UpdateBlogInput>
    const index = language === 'en' ? 0 : 1
    const { fields, append, remove } = useFieldArray({
        control,
        name: `blogContents.${index}.blogContentSections`,
    })

    const Row = ({ item, sectionIndex }: { item: BlogSectionInput; sectionIndex: number }) => {
        const [type, setType] = useState((item as unknown as BlogSectionInput).type ?? BlogSectionType.Article)

        const content = () => {
            switch (type) {
                case BlogSectionType.Article:
                    return (
                        <>
                            <TextField
                                {...register(`blogContents.${index}.blogContentSections.${sectionIndex}.content.title`)}
                                label="Title"
                                margin="normal"
                                fullWidth
                                id={`blogContents.${index}.blogContentSections.${sectionIndex}.content.title`}
                                defaultValue=""
                            />

                            <Controller
                                name={`blogContents.${index}.blogContentSections.${sectionIndex}.content.content`}
                                control={control}
                                defaultValue={(item as unknown as BlogSectionInput).content?.content ?? ''}
                                render={({ field }) => {
                                    const { onChange, value } = field
                                    return <WYSIWYGEditor {...field} />
                                }}
                            />
                        </>
                    )
                case BlogSectionType.Quote:
                    return (
                        <Controller
                            name={`blogContents.${index}.blogContentSections.${sectionIndex}.content.quote`}
                            control={control}
                            defaultValue={(item as unknown as BlogSectionInput).content?.quote ?? ''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Quote"
                                    margin="normal"
                                    fullWidth
                                    id={`blogContents.${index}.blogContentSections.${sectionIndex}.content.quote`}
                                    name={`blogContents.${index}.blogContentSections.${sectionIndex}.content.quote`}
                                    defaultValue=""
                                />
                            )}
                        />
                    )
                case BlogSectionType.Image:
                    return (
                        <>
                            <ImageInput
                                name={`blogContents.${index}.blogContentSections.${sectionIndex}.content.image`}
                                control={control}
                                label="Image"
                                existImage={
                                    queryResult?.data?.data?.blogContents?.[index]?.blogContentSections?.[sectionIndex]
                                        ?.content?.image ?? undefined
                                }
                            />
                            <TextField
                                {...register(
                                    `blogContents.${index}.blogContentSections.${sectionIndex}.content.caption`,
                                )}
                                label="Caption"
                                margin="normal"
                                fullWidth
                                id={`blogContents.${index}.blogContentSections.${sectionIndex}.content.caption`}
                                defaultValue=""
                            />
                            <input
                                type="hidden"
                                {...register(`blogContents.${index}.blogContentSections.${sectionIndex}.content.url`)}
                                value={
                                    queryResult?.data?.data?.blogContents?.[index]?.blogContentSections?.[sectionIndex]
                                        ?.content?.image ?? null
                                }
                            />
                        </>
                    )
            }
        }

        return (
            <Box
                key={sectionIndex}
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                }}
            >
                <Controller
                    name={`blogContents.${index}.blogContentSections.${sectionIndex}.type`}
                    control={control}
                    defaultValue={(item as unknown as BlogSectionInput).type ?? BlogSectionType.Article}
                    render={({ field: { onChange, value, ...rest } }) => {
                        return (
                            <FormControl {...rest} variant="standard" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                        setType(e.target.value as BlogSectionType)
                                    }}
                                    label="Type"
                                >
                                    <MenuItem value={BlogSectionType.Article}>{'Article'}</MenuItem>
                                    <MenuItem value={BlogSectionType.Quote}>{'Quote'}</MenuItem>
                                    <MenuItem value={BlogSectionType.Image}>{'Image'}</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
                {content()}
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <IconButton onClick={() => remove(sectionIndex)}>
                        <RemoveIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            append({} as BlogSectionInput)
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                mt: 2,
            }}
        >
            <Typography variant="h6">Component Sections</Typography>
            {fields.length === 0 && (
                <Button
                    variant="outlined"
                    sx={{
                        mt: 2,
                    }}
                    onClick={() => {
                        append({} as BlogSectionInput)
                    }}
                >
                    Add Component
                </Button>
            )}

            {fields.map((item, sectionIndex) => {
                return <Row key={sectionIndex} item={item} sectionIndex={sectionIndex} />
            })}
        </Box>
    )
}
