import { HttpError } from '@pankod/refine-core'
import { Create, Box, TextField, FormControlLabel, Checkbox } from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { CreateBannerInput, Banner } from 'gql/graphql'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { ImageInput } from 'components/ImageInput'
export const BannerCreate: React.FC = () => {
    const {
        refineCore: { formLoading },
        saveButtonProps,
        formState: { errors },
        register,
        control,
    } = useForm<Banner, HttpError, CreateBannerInput>({
        refineCoreProps: {
            dataProviderName: 'banners',
        },
    })
    const [startDate, setStartDate] = useState<DateTime | null>(DateTime.local())
    const [endDate, setEndDate] = useState<DateTime | null>(null)
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="name"
                    defaultValue=""
                    label="Name"
                    required
                />
                <FormControlLabel
                    control={
                        <Controller
                            name="showButton"
                            render={({ field }) => {
                                return <Checkbox checked={field.value ?? false} {...field} />
                            }}
                            control={control}
                        />
                    }
                    label={'Show Button?'}
                />
                <TextField
                    error={!!errors?.link}
                    helperText={errors.link?.message}
                    {...register('link', {
                        required: 'Link is required',
                    })}
                    margin="normal"
                    required
                    fullWidth
                    id="link"
                    label="Link"
                />
                {errors.link && <p>{errors.link.message}</p>}
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="buttonLabel"
                    defaultValue=""
                    label="Button Text"
                />
                <input type="hidden" value="en" {...register('images.0.language')} />
                <ImageInput
                    name="images.0.file"
                    control={control}
                    label="English version Image"
                    required
                    error={errors?.images?.[0]?.file ? 'required' : undefined}
                />
                <input type="hidden" value="zhHant" {...register('images.1.language')} />
                <ImageInput name="images.1.file" control={control} label="Chinese version Image" />
                <Controller
                    name="startDate"
                    control={control}
                    defaultValue={startDate}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="Start Date"
                                inputFormat="yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
                                value={value}
                                onChange={(event) => {
                                    onChange(event)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={!!error} helperText={error?.message} required />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                <br />
                <Controller
                    name="endDate"
                    defaultValue={null}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="End Date"
                                inputFormat="yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
                                value={value}
                                onChange={(event) => {
                                    onChange(event)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={!!error} helperText={error?.message} />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                <TextField
                    error={!!errors?.sortIndex}
                    helperText={errors.sortIndex?.message}
                    {...register('sortIndex')}
                    margin="normal"
                    fullWidth
                    id="sortIndex"
                    label="Sort Index"
                    name="sortIndex"
                    defaultValue=""
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Create>
    )
}
