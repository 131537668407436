import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import {
    GetUsersDocument,
    GetUserDocument,
    UpdateUserInput,
    UpdateUserDocument,
    CreateUserInput,
    CreateUserDocument,
    UserStatus,
} from '../../gql/graphql'
// import { UpdateUserInput } from 'gql/graphql'

export const UsersDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetUsersDocument, { skip: paging.skip, limit: paging.limit })
            return {
                data: result.adminUsers.items,
                total: result.adminUsers.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetUserDocument, { id })
            return { data: result.adminUser }
        },
        async update({
            variables,
            id,
        }: {
            variables: {
                countryCode: string
                phone: string
                status: UserStatus
            }
            id: string
        }) {
            const { countryCode, status, ...input } = variables
            const result = await client.request(UpdateUserDocument, {
                id,
                input: {
                    ...input,
                    status,
                    countryCode,
                },
            })
            return {
                data: result.adminUserUpdate,
            }
        },
        async create({
            variables,
        }: {
            variables: {
                countryCode: string
                phone: string
                status: UserStatus
            }
        }) {
            const { countryCode, status, ...input } = variables
            const result = await client.request(CreateUserDocument, {
                input: {
                    ...input,
                    status,
                    countryCode,
                },
            })
            return {
                data: result.adminUserCreate,
            }
        },
    } as DataProvider)
