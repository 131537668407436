import React from 'react'
import { useRouterContext, TitleProps } from '@pankod/refine-core'
import { Button } from '@mui/material'
import logo from 'assets/logo.png'

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    const { Link } = useRouterContext()

    return (
        <Button fullWidth variant="text" disableRipple>
            <Link to="/">
                {collapsed ? (
                    <img src={logo} alt="Two-hands" width="28px" style={{ maxHeight: '38px' }} />
                ) : (
                    <img src={logo} alt="Two-hands" width="140px" />
                )}
            </Link>
        </Button>
    )
}
