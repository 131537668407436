import React from 'react'
import { useDataGrid, DataGrid, GridColumns, Stack, EditButton, List, TextFieldComponent } from '@pankod/refine-mui'

import { Config } from 'gql/graphql'

const columns: GridColumns<Config> = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.name} />
        },
    },
    {
        field: 'value.en',
        headerName: 'Value(English)',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <>
                    <TextFieldComponent value={params.row.value.en} />
                </>
            )
        },
    },
    {
        field: 'value.zhHant',
        headerName: 'Value(ZH)',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <>
                    <TextFieldComponent value={params.row.value.zhHant ?? ''} />
                </>
            )
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    {/* <ShowButton hideText recordItemId={params.row.id} /> */}
                    <EditButton hideText recordItemId={params.row.id} />
                </Stack>
            )
        },
    },
]

export const ConfigList: React.FC = () => {
    const { dataGridProps } = useDataGrid<Config>({
        dataProviderName: 'configs',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
