import { GetOneResponse, QueryObserverResult } from '@pankod/refine-core'
import { Box, Button, FormControlLabel, Grid, IconButton, Stack, Typography } from '@pankod/refine-mui'
import { Controller, useController, useFormContext } from '@pankod/refine-react-hook-form'
import { Icon } from 'components/Icon'
import { BACKEND_URL } from 'config'
import { Shop, ShopImage, ShopImageType } from 'gql/graphql'
import { useEffect, useState } from 'react'

export interface GalleryInputProps {
    limit: number
    name: string
    label: string
    images?: Partial<ShopImage>[]
    control?: any
}

type GalleryImage = {
    url: string
    id?: string
}

export const GalleryInput = ({ limit, name, label, control, images = [] }: GalleryInputProps) => {
    const { field } = useController({ name, control })

    const [gallaryImages, setGalleryImages] = useState<GalleryImage[]>(
        images.map(({ url, id }) => ({
            id: id as string,
            url: `${BACKEND_URL}${url}`,
        })),
    )

    useEffect(() => {
        if (images.length > 0) {
            setGalleryImages(
                images.map(({ url, id }) => ({
                    id: id as string,
                    url: `${BACKEND_URL}${url}`,
                })),
            )
        }
    }, [images])
    const [files, setFiles] = useState<File[]>([])

    const removeFile = (file: File) => () => {
        const updatedList = [...files]
        updatedList.splice(files.indexOf(file), 1)
        setFiles(updatedList)
        field.onChange(updatedList)
        const input = document.getElementById(`${name}-input`) as HTMLInputElement
        if (input) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            input.value = updatedList as any
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'relative',
                gap: 1,
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    marginTop: 5,
                    paddingBottom: 0,
                }}
            >
                Other Images
            </Typography>
            {gallaryImages && gallaryImages.length > 0 && (
                <Box>
                    <Grid container columns={4} spacing={2}>
                        {gallaryImages.map((galleryImage, index) => (
                            <Grid
                                item
                                key={galleryImage.id || index}
                                xs={2}
                                md={2}
                                lg={1}
                                sx={{
                                    position: 'relative',
                                    aspectRatio: '1 / 1',
                                }}
                            >
                                <picture>
                                    <img
                                        alt=""
                                        src={galleryImage.url}
                                        key={galleryImage.id}
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            objectFit: 'cover',
                                            aspectRatio: '1 / 1',
                                        }}
                                    />
                                </picture>
                                <Controller
                                    name={`removeImages[${galleryImage.id}]`}
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <IconButton
                                            aria-label="Cancel"
                                            color="primary"
                                            sx={{
                                                position: 'absolute',
                                                top: '20px',
                                                right: '4px',
                                                width: '1.25rem',
                                                height: '1.25rem',
                                                padding: 0.5,
                                                color: 'white',
                                                backgroundColor: '#222222',
                                                '&:hover': {
                                                    backgroundColor: '#222222',
                                                    opacity: 0.8,
                                                },
                                            }}
                                            onClick={() => {
                                                field.onChange(true)
                                                setGalleryImages((prev) => prev.filter((_, i) => i !== index))
                                                removeFile(files[index])
                                            }}
                                        >
                                            <Icon fontSize="small" fontWeight="bold">
                                                close
                                            </Icon>
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            {gallaryImages.length < limit && (
                <Stack justifyContent="center" sx={{ textAlign: 'center', position: 'relative' }}>
                    <FormControlLabel
                        control={
                            <Controller
                                name={name}
                                defaultValue={''}
                                control={control}
                                render={({ field: { onBlur, name, ref } }) => (
                                    <input
                                        onBlur={onBlur}
                                        name={name}
                                        ref={ref}
                                        id={`${name}-input`}
                                        type="file"
                                        onChange={(e) => {
                                            console.log(`onchnage`)
                                            if (!e.target.files) return
                                            const objectUrls = Array.from(e.target.files).map((file) =>
                                                URL.createObjectURL(file),
                                            )

                                            setGalleryImages((prev) => [
                                                ...prev,
                                                ...objectUrls.map((url) => ({ url, id: undefined })),
                                            ])

                                            const newFiles = Object.values(e.target.files).map((file: File) => file)
                                            if (newFiles) {
                                                const updatedList = [...files, ...newFiles]
                                                if (updatedList.length > limit || newFiles.length > 3) {
                                                    return alert('maxFiles: ' + limit)
                                                }
                                                setFiles(updatedList)
                                                field.onChange(updatedList)
                                            }
                                        }}
                                        multiple={true}
                                        accept="image/jpg, image/png, image/jpeg"
                                        style={{
                                            display: 'none',
                                        }}
                                    />
                                )}
                            />
                        }
                        label={
                            <Button
                                variant="outlined"
                                sx={{
                                    mt: 2,
                                }}
                                onClick={() => {
                                    const input = document.getElementById(`${name}-input`) as HTMLInputElement
                                    if (input) {
                                        input.click()
                                    }
                                }}
                            >
                                Add Images
                            </Button>
                        }
                    />
                </Stack>
            )}
        </Box>
    )
}
