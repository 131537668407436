import { useShow } from '@pankod/refine-core'
import { Show, Stack, Typography } from '@pankod/refine-mui'

import { Category } from 'gql/graphql'

export const CategoriesShow: React.FC = () => {
    const { queryResult } = useShow<Category>({ dataProviderName: 'categories' })

    const { data, isLoading } = queryResult
    const record = data?.data

    const hasIcon = record?.icon != null
    const hasChildren = record?.children != null

    const getChildrenNames = (children: Category[] | undefined | null) => {
        return children?.map((child: Category) => child.name.en) ?? []
    }

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Name in English
                </Typography>
                <Typography variant="body2">{record?.name?.en}</Typography>
                <Typography variant="body1" fontWeight="bold">
                    Name in Traditional Chinese
                </Typography>
                <Typography variant="body2">{record?.name?.zhHant}</Typography>
                {hasChildren && (
                    <>
                        <Typography variant="body1" fontWeight="bold">
                            Sub Categories
                        </Typography>
                        <Typography variant="body2">{getChildrenNames(record?.children).join(',')}</Typography>
                    </>
                )}
                {hasIcon && (
                    <>
                        <Typography variant="body1" fontWeight="bold">
                            Icon
                        </Typography>
                        <Typography variant="body2">{record.icon}</Typography>
                    </>
                )}
            </Stack>
        </Show>
    )
}
