import { GraphQLClient } from 'graphql-request'
import { CrudFilter, DataProvider, LogicalFilter } from '@pankod/refine-core'
import {
    CreateCategoryInput,
    GetCategoriesDocument,
    GetCategoryDocument,
    UpdateCategoryDocument,
    CreateCategoryDocument,
} from '../../gql/graphql'
import { UpdateCategoryInput } from 'gql/graphql'

export const CategoriesDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination, filters }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}

            const query: any = {}
            if (filters && filters.length > 0 && filters.find((f) => (f as LogicalFilter).field === 'parentOnly')) {
                const parentOnlyFilter = filters.find(
                    (f) => (f as LogicalFilter).field === 'parentOnly',
                ) as LogicalFilter
                if (parentOnlyFilter.value === true) {
                    query['parentOnly'] = true
                }
            }
            const result = await client.request(GetCategoriesDocument, { ...paging, ...query })
            return {
                data: result.adminCategories.items,
                total: result.adminCategories.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetCategoryDocument, { id })
            return { data: result.category }
        },
        async update({ variables, id }: { variables: UpdateCategoryInput; id: string }) {
            const { name, icon, parentId } = variables
            const sortIndex = variables.sortIndex ? Number(variables.sortIndex) : variables.sortIndex
            const updateCategoryInput = {
                name: name,
                icon: icon,
                sortIndex: sortIndex,
                parentId: parentId,
            }

            const result = await client.request(UpdateCategoryDocument, { id, updateCategoryInput })
            return {
                data: result.adminCategoryUpdate,
            }
        },
        async create({ variables }: { variables: CreateCategoryInput }) {
            const { name, icon, parentId, code } = variables
            const sortIndex = variables.sortIndex ? Number(variables.sortIndex) : variables.sortIndex
            const input = {
                name: name,
                icon: icon,
                sortIndex: sortIndex,
                parentId: parentId,
                code: code
            }

            const result = await client.request(CreateCategoryDocument, { input: input })
            return {
                data: result.adminCategoryCreate,
            }
        },
    } as DataProvider)
