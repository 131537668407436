import { HttpError } from '@pankod/refine-core'
import {
    Edit,
    Box,
    TextField,
    Autocomplete,
    useAutocomplete,
    Tabs,
    Tab,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    FormGroup,
    InputLabel,
    Select,
    MenuItem,
} from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { DeliveryOptionForm } from 'components/DeliveryOptionForm'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { ShopSocialMediaDynamicForm } from 'components/ShopSocialMediaDynamicForm'
import {
    AuthenticationType,
    Category,
    Shop,
    AdminUpdateShopInput,
    ShopImageType,
    User,
    AcceptedCountries,
} from 'gql/graphql'
import { SyntheticEvent, useEffect, useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { BACKEND_URL } from '../../config'
import { LogoInput } from 'components/LogoInput'
import { FeatureImageInput } from 'components/FeatureImageInput'
// import { OtherImagesInput } from 'components/OtherImagesInput'
import { ShopEditInput } from './dataProvider'
import { ServicesSection } from 'components/ServicesSection'
import { GalleryInput } from 'components/OtherImagesInput'

export const ShopEdit: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
        control,
        reset,
        getValues,
        watch,
    } = useForm<Shop, HttpError, ShopEditInput>({
        refineCoreProps: {
            dataProviderName: 'shops',
            resource: 'shops',
        },
    })

    useEffect(() => {
        return () => {
            queryResult?.remove()
        }
    }, [])

    const [isOnline, setIsOnline] = useState(false)

    useEffect(() => {
        const subscription = watch(() => {
            if (watch('isOnline')) {
                setIsOnline(true)
            } else {
                setIsOnline(false)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const { autocompleteProps: authenticationTypeAutocompleteProps } = useAutocomplete<AuthenticationType>({
        resource: 'authenticationTypes',
        dataProviderName: 'authenticationTypes',
    })

    const { autocompleteProps: userProps } = useAutocomplete({
        resource: 'users',
        dataProviderName: 'users',
    })

    useEffect(() => {
        if (queryResult?.data?.data) {
            reset({
                ...getValues(),
                userId: queryResult.data.data.user?.id,
            })
        }
    }, [formLoading])

    const images = queryResult?.data?.data?.images ?? []
    console.log('images', images)
    const featureImage = images.find(({ type }) => type === ShopImageType.Feature)
    const galleryImages = images.filter(({ type }) => type === ShopImageType.Gallery)
    const logoImage = images.find(({ type }) => type === ShopImageType.Logo)

    const ShopForm = (
        <>
            <LogoInput control={control} formLoading={formLoading} queryResult={queryResult} />
            <FeatureImageInput control={control} formLoading={formLoading} queryResult={queryResult} />
            <FormControlLabel
                control={
                    <Controller
                        name="isOnline"
                        render={({ field }) => {
                            return <Checkbox checked={field.value ?? false} {...field} />
                        }}
                        control={control}
                    />
                }
                label={'Is Online Shop?'}
            />
            <MultilingualTextField errors={errors} register={register} margin="normal" required fullWidth name="name" />
            <MultilingualTextField
                errors={errors}
                register={register}
                margin="normal"
                fullWidth
                name="description"
                multiline
                rows={4}
            />
            <Controller
                control={control}
                name="country"
                defaultValue={AcceptedCountries.Hk}
                render={({ field: { onChange, value, ...rest } }) => {
                    return (
                        <FormControl {...rest} variant="standard" sx={{ m: 1, width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={value}
                                onChange={(e) => {
                                    onChange(e.target.value)
                                }}
                                label="Country Code"
                            >
                                <MenuItem value={AcceptedCountries.Hk}>{'Hong Kong'}</MenuItem>
                                <MenuItem value={AcceptedCountries.Jp}>{'Japan'}</MenuItem>
                            </Select>
                        </FormControl>
                    )
                }}
            />
            <TextField
                error={!!errors?.website}
                helperText={errors.website?.message}
                {...register('website')}
                margin="normal"
                required
                fullWidth
                id="website"
                label="Website"
                defaultValue={' '}
            />
            <ServicesSection
                control={control}
                queryResult={queryResult}
                formLoading={formLoading}
                reset={reset}
                getValues={getValues}
            />
            <Controller
                control={control}
                name="authentications"
                defaultValue={null as any}
                render={({ field }) => {
                    const { value, ...rest } = field
                    const newValues = value as AuthenticationType[] | null
                    return (
                        <Autocomplete
                            {...rest}
                            {...authenticationTypeAutocompleteProps}
                            multiple
                            value={newValues ?? []}
                            onChange={(_, value) => {
                                field.onChange(value)
                            }}
                            getOptionLabel={(item) => {
                                return (
                                    authenticationTypeAutocompleteProps?.options?.find((p) => p.id === item.id)?.name
                                        ?.en ?? ''
                                )
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return value === undefined || option.id.toString() === value.id.toString()
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Authentication Types"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.authentications}
                                    helperText={errors.authentications?.message}
                                />
                            )}
                        />
                    )
                }}
            />
            <Controller
                control={control}
                name="userId"
                defaultValue={null as any}
                render={({ field }) => {
                    const { value, ...rest } = field
                    return (
                        <Autocomplete
                            {...rest}
                            {...userProps}
                            value={value}
                            onChange={(_, value) => {
                                field.onChange(value?.id)
                            }}
                            getOptionLabel={(item) => {
                                const user =
                                    userProps?.options?.find((p) => p.id === item) ||
                                    userProps?.options?.find((p) => p.id === item.id)
                                return user ? `${user?.countryCode} ${user?.phone}` : ''
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value || value === undefined
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="User"
                                    margin="normal"
                                    variant="outlined"
                                    error={!!errors.authentications}
                                    helperText={errors.authentications?.message}
                                />
                            )}
                        />
                    )
                }}
            />
            <ShopSocialMediaDynamicForm control={control} />
            <DeliveryOptionForm
                formLoading={formLoading}
                queryResult={queryResult}
                control={control}
                reset={reset}
                getValues={getValues}
            />
            <GalleryInput control={control} name="normalImages" label={'Images'} limit={4} images={galleryImages} />
        </>
    )
    const StoreForm = (
        <>
            <Typography variant="h6" gutterBottom>
                Store (Only enable One Store for now)
            </Typography>
            <MultilingualTextField
                errors={errors}
                register={register}
                margin="normal"
                fullWidth
                name="stores.0.name"
                label="Store Name"
            />
            <MultilingualTextField
                errors={errors}
                register={register}
                margin="normal"
                fullWidth
                name="stores.0.description"
                label="Store Description"
                multiline
                rows={4}
            />
            <TextField
                {...register('stores.0.address.addressLine')}
                margin="normal"
                fullWidth
                label="Address Line"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.address.city')}
                margin="normal"
                fullWidth
                label="City"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.address.state')}
                margin="normal"
                fullWidth
                label="State"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.address.country')}
                margin="normal"
                fullWidth
                label="Country"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.address.zipCode')}
                margin="normal"
                fullWidth
                label="Postal Code"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.address.googlePlaceId')}
                margin="normal"
                fullWidth
                label="Google Place Id"
                defaultValue={' '}
            />
            <TextField
                {...register('stores.0.latitude', {
                    validate: (value) => {
                        if (getValues('isOnline') === false && !value) {
                            return 'Latitude is required'
                        }
                        if (value && value > 90 && value < -90) {
                            return 'Latitude must be less than 90 and greater than -90'
                        }
                        return true
                    },
                })}
                margin="normal"
                fullWidth
                label="Latitude"
                type="number"
                required
                defaultValue={null as any}
                error={!!errors.stores?.[0]?.latitude}
                helperText={errors.stores?.[0]?.latitude?.message}
            />
            <TextField
                {...register('stores.0.longitude', {
                    validate: (value) => {
                        if (getValues('isOnline') === false && !value) {
                            return 'Longitude is required'
                        }
                        if (value && value > 180 && value < -180) {
                            return 'Longitude must be less than 180 and greater than -180'
                        }
                        return true
                    },
                })}
                margin="normal"
                fullWidth
                label="Longitude"
                type="number"
                required
                defaultValue={null as any}
                error={!!errors.stores?.[0]?.longitude}
                helperText={errors.stores?.[0]?.longitude?.message}
            />
            <TextField
                {...register('stores.0.openingTime')}
                margin="normal"
                fullWidth
                label="Opening Time"
                type="time"
                defaultValue={'00:00'}
            />
            <TextField
                {...register('stores.0.closingTime')}
                margin="normal"
                fullWidth
                label="Closing Time"
                type="time"
                defaultValue={'23:00'}
            />
            {/* Workding days */}
            <FormControl component="fieldset" sx={{ mt: 2 }}>
                <FormLabel component="legend">Working Days</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.monday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Monday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.tuesday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Tuesday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.wednesday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Wednesday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.thursday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Thursday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.friday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Friday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.saturday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Saturday"
                    />
                    <FormControlLabel
                        control={
                            <Controller
                                name="stores.0.workingDays.sunday"
                                render={({ field }) => {
                                    return <Checkbox checked={field.value ?? false} {...field} />
                                }}
                                control={control}
                            />
                        }
                        label="Sunday"
                    />
                </FormGroup>
            </FormControl>
        </>
    )

    const [tabValue, setTabValue] = useState<'shop' | 'store'>('shop')
    const handleChange = (event: React.SyntheticEvent, newValue: 'shop' | 'store') => {
        setTabValue(newValue)
    }

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Shop" value="shop" />
                            <Tab
                                label={
                                    <Typography
                                        sx={{
                                            color: errors.stores?.[0] ? 'error.main' : 'inherit',
                                        }}
                                    >
                                        Store
                                    </Typography>
                                }
                                value="store"
                                disabled={isOnline}
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="shop">
                        <Box>{ShopForm}</Box>
                    </TabPanel>
                    <TabPanel value="store">
                        <Box>{StoreForm}</Box>
                    </TabPanel>
                </TabContext>
            </Box>
        </Edit>
    )
}
