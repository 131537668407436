import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import {
    GetBlogsDocument,
    GetBlogDocument,
    UpdateBlogInput,
    UpdateBlogDocument,
    CreateBlogInput,
    CreateBlogDocument,
    BlogSectionType,
} from '../../gql/graphql'

export const BlogsDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetBlogsDocument, { skip: paging.skip, limit: paging.limit })
            return {
                data: result.adminBlogs.items,
                total: result.adminBlogs.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetBlogDocument, { id })
            return { data: result.adminBlog }
        },
        async update({ variables, id }: { variables: UpdateBlogInput; id: string }) {
            const { featureImage, blogContents, ...rest } = variables
            const newBlogContentsInput = blogContents.map((blogContent) => {
                const { blogContentSections, ...rest } = blogContent
                const newBlogContentSectionsInput = blogContentSections.map((blogContentSection) => {
                    if (blogContentSection.type === BlogSectionType.Image) {
                        const { content, ...rest } = blogContentSection
                        const { image, caption } = content as unknown as {
                            image: File | string
                            caption: string
                        }
                        return {
                            ...rest,
                            content: {
                                ...(image instanceof File && {
                                    image,
                                }),
                                ...(!(image instanceof File) && {
                                    url: image,
                                }),
                                caption,
                            },
                        }
                    }
                    return blogContentSection
                })

                return {
                    ...rest,
                    blogContentSections: newBlogContentSectionsInput,
                }
            })
            console.log({
                ...rest,
                blogContents: newBlogContentsInput,
                ...(featureImage &&
                    featureImage instanceof File && {
                        featureImage,
                    }),
            })
            const result = await client.request(UpdateBlogDocument, {
                id,
                input: {
                    ...rest,
                    blogContents: newBlogContentsInput,
                    ...(featureImage &&
                        featureImage instanceof File && {
                            featureImage,
                        }),
                },
            })
            return {
                data: result.adminBlogUpdate,
            }
        },
        async create({ variables }: { variables: CreateBlogInput }) {
            try {
                const result = await client.request(CreateBlogDocument, {
                    input: {
                        ...variables,
                    },
                })
                return {
                    data: result.adminBlogCreate,
                }
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    } as DataProvider)
