import { GetOneResponse, HttpError, QueryObserverResult, useList } from '@pankod/refine-core'
import { Box, TextField, Icon, FormLabel, Typography, Checkbox } from '@pankod/refine-mui'
import { Controller } from '@pankod/refine-react-hook-form'
import { DeliveryOption, Shop } from 'gql/graphql'
import { useEffect, useState } from 'react'

export const DeliveryOptionForm = ({
    formLoading,
    queryResult,
    control,
    reset,
    getValues,
}: {
    formLoading: boolean
    queryResult: QueryObserverResult<GetOneResponse<Shop>, unknown> | undefined
    control: any
    reset: any
    getValues: any
}) => {
    const { data: deliveryOptions, isLoading } = useList<DeliveryOption>({
        resource: 'deliveryOptions',
        dataProviderName: 'deliveryOptions',
    })

    useEffect(() => {
        setTimeout(() => {
            if (queryResult?.data) {
                const shopDeliveryOptions = queryResult.data.data.deliveryOptions
                const selectedDeliveryOptions = shopDeliveryOptions?.reduce((acc, deliveryOption) => {
                    if (!deliveryOption.deliveryOption) {
                        return acc
                    }
                    if (acc?.[deliveryOption.deliveryOption.id] && deliveryOption.deliveryServiceProvider?.id) {
                        return {
                            ...acc,
                        }
                    }

                    return {
                        ...acc,
                        [deliveryOption.deliveryOption.id]: {
                            selected: true,
                            additionalInfo: deliveryOption.deliveryServiceProvider?.id
                                ? ''
                                : deliveryOption.customInfo ?? '',
                        },
                    }
                }, {} as { [key: string]: { selected: boolean; additionalInfo: string } })

                const selectedServiceProviders = shopDeliveryOptions
                    ?.map((deliveryOption) => {
                        return {
                            id: deliveryOption.deliveryServiceProvider?.id ?? null,
                            additionalInfo: deliveryOption.customInfo ?? '',
                            deliveryOptionId: deliveryOption.deliveryOption?.id ?? '',
                        }
                    })
                    .reduce((acc, deliveryServiceProvider) => {
                        if (!deliveryServiceProvider.id) {
                            return acc
                        }
                        return {
                            ...acc,
                            [deliveryServiceProvider.id]: {
                                selected: true,
                                additionalInfo: deliveryServiceProvider.additionalInfo ?? '',
                                deliveryOptionId: deliveryServiceProvider.deliveryOptionId ?? '',
                            },
                        }
                    }, {} as { [key: string]: { selected: boolean; additionalInfo: string } })

                if (selectedDeliveryOptions) {
                    reset({
                        ...getValues(),
                        deliveryOptions: selectedDeliveryOptions,
                    })
                    const selectedDeliveryOptionsIds = Object.keys(selectedDeliveryOptions).filter(
                        (key) => selectedDeliveryOptions[key].selected,
                    )
                    setSelectedDeliveryOptions(selectedDeliveryOptionsIds)
                }
                if (selectedServiceProviders) {
                    reset({
                        ...getValues(),
                        serviceProviders: selectedServiceProviders,
                    })
                    const selectedServiceProvidersIds = Object.keys(selectedServiceProviders).filter(
                        (key) => selectedServiceProviders[key].selected,
                    )
                    setSelectedServiceProviders(selectedServiceProvidersIds)
                }
            }
        }, 500)
    }, [formLoading, deliveryOptions, isLoading])
    const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState<string[]>([])
    const [selectedServiceProviders, setSelectedServiceProviders] = useState<string[]>([])

    return (
        <Box
            sx={{
                mt: 2,
            }}
        >
            <Typography variant="h6">Delivery Options</Typography>
            {deliveryOptions?.data.map((deliveryOption, key) => (
                <Box
                    key={key}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Controller
                        name={`deliveryOptions.${deliveryOption.id}.selected`}
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value, ...rest } }) => (
                            <>
                                <FormLabel
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        py: 1,
                                    }}
                                >
                                    <Typography
                                        color="primary"
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon
                                            fontSize="small"
                                            sx={{
                                                color: 'gold.main',
                                            }}
                                        >
                                            {deliveryOption.icon ?? 'place'}
                                        </Icon>
                                        {deliveryOption.name.en}
                                    </Typography>
                                    <Checkbox
                                        {...rest}
                                        checked={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            e.target.checked
                                                ? setSelectedDeliveryOptions((prev) => [...prev, deliveryOption.id])
                                                : setSelectedDeliveryOptions((prev) =>
                                                      prev.filter((item) => item !== deliveryOption.id),
                                                  )
                                        }}
                                        // value={deliveryOption.id}
                                        color="secondary"
                                    />
                                </FormLabel>
                            </>
                        )}
                    />
                    {deliveryOption.showAdditionalInfo && selectedDeliveryOptions.includes(deliveryOption.id) && (
                        <Controller
                            name={`deliveryOptions.${deliveryOption.id}.additionalInfo`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required={false}
                                    fullWidth
                                    label={deliveryOption.additionalInfoLabel && deliveryOption.additionalInfoLabel.en}
                                />
                            )}
                        />
                    )}
                    {deliveryOption.serviceProviders && deliveryOption.serviceProviders?.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderLeft: 2,
                                borderColor: 'rose.main',
                                pl: 3,
                                gap: 4,
                                py: 1,
                            }}
                        >
                            {deliveryOption.serviceProviders.map((serviceProvider, key) => (
                                <Box key={key}>
                                    <Controller
                                        name={`serviceProviders.${serviceProvider.id}.deliveryOptionId`}
                                        control={control}
                                        defaultValue={deliveryOption.id}
                                        render={({ field }) => <input {...field} type="hidden" />}
                                    />
                                    <Controller
                                        name={`serviceProviders.${serviceProvider.id}.selected`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value, ...rest } }) => (
                                            <FormLabel
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography color="primary">{serviceProvider?.name?.en}</Typography>
                                                <Checkbox
                                                    {...rest}
                                                    checked={value}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        e.target.checked
                                                            ? setSelectedServiceProviders((prev) => [
                                                                  ...prev,
                                                                  serviceProvider.id,
                                                              ])
                                                            : setSelectedServiceProviders((prev) =>
                                                                  prev.filter((item) => item !== serviceProvider.id),
                                                              )
                                                    }}
                                                    color="secondary"
                                                />
                                            </FormLabel>
                                        )}
                                    />
                                    {serviceProvider.showAdditionalInfo &&
                                        selectedServiceProviders.includes(serviceProvider.id) && (
                                            <Controller
                                                name={`serviceProviders.${serviceProvider.id}.additionalInfo`}
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        required={false}
                                                        label={
                                                            serviceProvider.additionalInfoLabel &&
                                                            serviceProvider.additionalInfoLabel.en
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    )
}
