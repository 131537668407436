import { HttpError } from '@pankod/refine-core'
import {
    Create,
    Box,
    TextField,
    Autocomplete,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ToggleButton,
    FormControlLabel,
    Checkbox,
} from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { AcceptedCountries, SearchSuggestion, CreateSearchSuggestionInput } from 'gql/graphql'
import { useEffect, useState } from 'react'

export const SearchSuggestionCreate: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        control,
        formState: { errors },
        register,
        watch,
    } = useForm<SearchSuggestion, HttpError, CreateSearchSuggestionInput>({
        refineCoreProps: {
            dataProviderName: 'searchSuggestions',
        },
    })

    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        const subscription = watch(() => {
            if (watch('enabled')) {
                setEnabled(true)
            } else {
                setEnabled(false)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <TextField
                    error={!!errors?.code}
                    helperText={errors.code?.message ?? 'A unique code to identify the search suggestion'}
                    {...register('code', { required: 'code is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Code"
                    name="code"
                    defaultValue=""
                />
                <Controller
                    control={control}
                    name="country"
                    defaultValue={AcceptedCountries.Hk}
                    render={({ field: { onChange, value, ...rest } }) => {
                        return (
                            <FormControl {...rest} variant="standard" sx={{ m: 1, width: '100%' }}>
                                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    label="Country Code"
                                >
                                    <MenuItem value={AcceptedCountries.Hk}>{'Hong Kong'}</MenuItem>
                                    <MenuItem value={AcceptedCountries.Jp}>{'Japan'}</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="name"
                    defaultValue=" "
                    label={'Display Name'}
                />
                <TextField
                    {...register('latitude', {
                        validate: (value) => {
                            if (value === null || value === undefined) {
                                return 'Latitude is required'
                            }
                            if (value && value > 90 && value < -90) {
                                return 'Latitude must be less than 90 and greater than -90'
                            }
                            return true
                        },
                    })}
                    margin="normal"
                    fullWidth
                    label="Latitude"
                    type="number"
                    required
                    defaultValue={null as any}
                    error={!!errors.latitude}
                    InputLabelProps={{ shrink: true }}
                    helperText={errors.latitude?.message ?? 'Latitude must be less than 90 and greater than -90'}
                />
                <TextField
                    {...register('longitude', {
                        validate: (value) => {
                            if (value === null || value === undefined) {
                                return 'Longitude is required'
                            }
                            if (value && value > 180 && value < -180) {
                                return 'Longitude must be less than 180 and greater than -180'
                            }
                            return true
                        },
                    })}
                    margin="normal"
                    fullWidth
                    label="Longitude"
                    type="number"
                    required
                    defaultValue={null as any}
                    error={!!errors.longitude}
                    InputLabelProps={{ shrink: true }}
                    helperText={errors.longitude?.message ?? 'Longitude must be less than 180 and greater than -180'}
                />
                <TextField
                    error={!!errors?.sortIndex}
                    helperText={errors.sortIndex?.message}
                    {...register('sortIndex')}
                    margin="normal"
                    fullWidth
                    id="sortIndex"
                    label="Sort Index"
                    name="sortIndex"
                    defaultValue=" "
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                    control={
                        <Controller
                            name="enabled"
                            render={({ field }) => {
                                return <Checkbox checked={field.value ?? false} {...field} />
                            }}
                            control={control}
                        />
                    }
                    label={'Enabled'}
                />
            </Box>
        </Create>
    )
}
