import { HttpError } from '@pankod/refine-core'
import { Create, Box, TextField, FormControlLabel, Checkbox, Tab } from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { CreateBlogInput, Blog } from 'gql/graphql'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DateTime } from 'luxon'
import { useState } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { ImageInput } from 'components/ImageInput'
import { BlogContentFrom } from './content'
export const BlogCreate: React.FC = () => {
    const form = useForm<Blog, HttpError, CreateBlogInput>({
        refineCoreProps: {
            dataProviderName: 'blogs',
        },
    })
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
        control,
    } = form
    const [publishedAt, setPublishedAt] = useState<DateTime | null>(DateTime.local())
    const [tabValue, setTabValue] = useState<'en' | 'zhHant'>('en')
    const handleChange = (event: React.SyntheticEvent, newValue: 'en' | 'zhHant') => {
        setTabValue(newValue)
    }
    console.log('errors', errors)
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="English" value="en" />
                            <Tab label="Chinese" value="zhHant" />
                        </TabList>
                    </Box>
                    <TabPanel value="en">
                        <BlogContentFrom language="en" form={form} />
                    </TabPanel>
                    <TabPanel value="zhHant">
                        <BlogContentFrom language="zhHant" form={form} />
                    </TabPanel>
                </TabContext>
                <TextField
                    error={!!errors?.slug}
                    helperText={errors.slug?.message}
                    {...register('slug', { required: 'slug is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="slug"
                    label="Slug"
                    name="slug"
                    defaultValue=""
                />
                <Controller
                    name="publishedAt"
                    control={control}
                    defaultValue={publishedAt}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="publishedAt"
                                inputFormat="yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
                                value={value}
                                onChange={(event) => {
                                    onChange(event)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={!!error} helperText={error?.message} required />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                <ImageInput
                    name={`featureImage`}
                    control={control}
                    label="Feature Image"
                    required
                    error={errors?.featureImage?.message}
                    existImage={queryResult?.data?.data?.featureImage}
                />
                <TextField
                    error={!!errors?.author}
                    helperText={errors?.author?.message}
                    {...register('author', { required: 'Author is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="author"
                    label="Author"
                    name="author"
                    defaultValue=""
                />
                <br />
            </Box>
        </Create>
    )
}
