import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import { GetDeliveryServiceProvidersDocument, GetDeliveryServiceProviderDocument } from '../../gql/graphql'
// import { UpdateDeliveryServiceProviderInput } from 'gql/graphql'

export const DeliveryServiceProvidersDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination, hasPagination }) => {
            const pageSize = pagination?.pageSize ?? 10
            const current = pagination?.current ?? 1
            const paging = hasPagination
                ? {
                      skip: (current - 1) * pageSize,
                      limit: pageSize,
                  }
                : {}
            const result = await client.request(GetDeliveryServiceProvidersDocument, { skip: paging.skip, limit: paging.limit })
            return {
                data: result.adminDeliveryServiceProviders.items,
                total: result.adminDeliveryServiceProviders.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetDeliveryServiceProviderDocument, { id })
            return { data: result.adminDeliveryServiceProvider }
        },
        // async update({ variables, id }: { variables: UpdateDeliveryServiceProviderInput; id: string }) {
        //     const { name, icon, parentId } = variables
        //     const sortIndex = variables.sortIndex ? Number(variables.sortIndex) : variables.sortIndex
        //     const updateDeliveryServiceProviderInput = {
        //         name: name,
        //         icon: icon,
        //         sortIndex: sortIndex,
        //         parentId: parentId,
        //     }

        //     const result = await client.request(UpdateDeliveryServiceProviderDocument, { id, updateDeliveryServiceProviderInput })
        //     return {
        //         data: result.adminDeliveryServiceProviderUpdate,
        //     }
        // },
    } as DataProvider)
