import React from 'react'
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    Stack,
    EditButton,
    ShowButton,
    List,
    TextFieldComponent,
} from '@pankod/refine-mui'

import { User } from 'gql/graphql'

const columns: GridColumns<User> = [
    {
        field: 'phone',
        headerName: 'Phone',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={`${params.row.countryCode} ${params.row.phone}`} />
        },
    },
    {
        field: 'createdAt',
        headerName: 'Create Date',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.createdAt} />
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    {/* <ShowButton hideText recordItemId={params.row.id} /> */}
                    <EditButton hideText recordItemId={params.row.id} />
                </Stack>
            )
        },
    },
]

export const UserList: React.FC = () => {
    const { dataGridProps } = useDataGrid<User>({
        dataProviderName: 'users',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
