import { GraphQLClient } from 'graphql-request'
import { BaseKey, DataProvider } from '@pankod/refine-core'
import {
    GetSearchSuggestionsDocument,
    GetSearchSuggestionDocument,
    UpdateSearchSuggestionInput,
    UpdateSearchSuggestionDocument,
    CreateSearchSuggestionDocument,
    CreateSearchSuggestionInput,
    RemoveSearchSuggestionDocument,
} from '../../gql/graphql'
// import { UpdateSearchSuggestionInput } from 'gql/graphql'

export const SearchSuggestionsDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination }) => {
            const result = await client.request(GetSearchSuggestionsDocument)
            return {
                data: result.adminSearchSuggestions.items,
                total: result.adminSearchSuggestions.total,
            }
        },
        deleteOne: async ({ id }: { resource: string; id: BaseKey }) => {
            console.log('deleteOne', id)
            const result = await client.request(RemoveSearchSuggestionDocument, { id: id.toString() })
            return {
                data: result.adminSearchSuggestionRemove,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetSearchSuggestionDocument, { id })
            return { data: result.adminSearchSuggestion }
        },
        async update({ variables, id }: { variables: UpdateSearchSuggestionInput; id: string }) {
            // filter variables only in type
            const { country, enabled, latitude, longitude, name, sortIndex } = variables
            const input = {
                country,
                enabled,
                latitude: Number(latitude),
                longitude: Number(longitude),
                name,
                sortIndex: Number(sortIndex) ?? 0,
            }

            const result = await client.request(UpdateSearchSuggestionDocument, { id, input })
            return {
                data: result.adminSearchSuggestionUpdate,
            }
        },
        async create({ variables }: { variables: CreateSearchSuggestionInput }) {
            // filter variables only in type
            const { code, country, enabled, latitude, longitude, name, sortIndex } = variables
            const input = {
                code,
                country,
                enabled,
                latitude: Number(latitude),
                longitude: Number(longitude),
                name,
                sortIndex: Number(sortIndex) ?? 0,
            }

            const result = await client.request(CreateSearchSuggestionDocument, { input })
            return {
                data: result.adminSearchSuggestionCreate,
            }
        },
    } as DataProvider)
