import { Refine } from '@pankod/refine-core'
import {
    notificationProvider,
    RefineSnackbarProvider,
    CssBaseline,
    GlobalStyles,
    Layout,
    ThemeProvider,
    LightTheme,
    ReadyPage,
    ErrorComponent,
    AuthPage,
    Icon,
} from '@pankod/refine-mui'

import { CategoriesDataProvider } from 'pages/categories/dataProvider'
import routerProvider from '@pankod/refine-react-router-v6'

import { authProvider } from './authProvider'
import { GraphQLClient } from 'graphql-request'
import { CategoriesList, CategoriesShow, CategoryEdit, CategoryCreate } from 'pages'
import { ShopsDataProvider } from 'pages/shops/dataProvider'
import { ShopList } from 'pages/shops/list'
import { SocialMediaList } from 'pages/socialMedias/list'
import { AuthenticationTypeList } from 'pages/authenticationTypes/list'
import { DeliveryServiceProviderList } from 'pages/deliveryServiceProviders/list'
import { DeliveryOptionList } from 'pages/deliveryOptions/list'
import { SocialMediasDataProvider } from 'pages/socialMedias/dataProvider'
import { DeliveryOptionsDataProvider } from 'pages/deliveryOptions/dataProvider'
import { DeliveryServiceProvidersDataProvider } from 'pages/deliveryServiceProviders/dataProvider'
import { AuthenticationTypesDataProvider } from 'pages/authenticationTypes/dataProvider'
import { Sider } from 'components/layout'
import { Title } from 'components/layout/title'
import { themeOptions } from 'theme'
import { LoginPage } from 'components/layout/login'
import { ShopEdit } from 'pages/shops/edit'
import { ShopCreate } from 'pages/shops/create'
import { UserList } from 'pages/users/list'
import { UsersDataProvider } from 'pages/users/dataProvider'
import { UserCreate } from 'pages/users/create'
import { UserEdit } from 'pages/users/edit'
const API_URL = process.env.REACT_APP_API_URL ?? 'https://uat-two-hands.anthonyleung.xyz/graphql'
import {
    CategoryOutlined,
    LocalShippingOutlined,
    PersonOutline,
    ScienceOutlined,
    StorefrontOutlined,
    ThumbUpOutlined,
    ShoppingBagOutlined,
    BadgeOutlined,
} from '@mui/icons-material'
import { BannersDataProvider } from 'pages/banners/dataProvider'
import { BannerList } from 'pages/banners/list'
import { BannerCreate } from 'pages/banners/create'
import { BannerEdit } from 'pages/banners/edit'
import { BlogsDataProvider } from 'pages/blogs/dataProvider'
import { BlogList } from 'pages/blogs/list'
import { BlogCreate } from 'pages/blogs/create'
import { BlogEdit } from 'pages/blogs/edit'
import { ConfigsDataProvider } from 'pages/configs/dataProvider'
import { ConfigList } from 'pages/configs/list'
import { ConfigEdit } from 'pages/configs/edit'
import { SearchSuggestionList } from 'pages/searchSuggestions/list'
import { SearchSuggestionEdit } from 'pages/searchSuggestions/edit'
import { SearchSuggestionsDataProvider } from 'pages/searchSuggestions/dataProvider'
import { SearchSuggestionCreate } from 'pages/searchSuggestions/create'
export class APIError extends Error {
    statusCode: number
    source: any
    constructor(message: string, statusCode: number, source: any) {
        super(message)
        this.statusCode = statusCode
        this.source = source
    }
}

const client = new GraphQLClient(API_URL, {
    responseMiddleware: (response) => {
        if (response instanceof Error) {
            const err = response as any
            const errorRes = err?.response?.errors?.[0]
            throw new APIError(
                errorRes?.extensions?.response?.message,
                errorRes?.extensions?.response?.statusCode,
                errorRes?.extensions,
            )
        }
        return response
    },
})

function App() {
    return (
        <ThemeProvider theme={themeOptions}>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
            <RefineSnackbarProvider>
                <Refine
                    dataProvider={{
                        default: CategoriesDataProvider(client),
                        authenticationTypes: AuthenticationTypesDataProvider(client),
                        categories: CategoriesDataProvider(client),
                        shops: ShopsDataProvider(client),
                        socialMedias: SocialMediasDataProvider(client),
                        deliveryOptions: DeliveryOptionsDataProvider(client),
                        deliveryServiceProviders: DeliveryServiceProvidersDataProvider(client),
                        users: UsersDataProvider(client),
                        banners: BannersDataProvider(client),
                        blogs: BlogsDataProvider(client),
                        configs: ConfigsDataProvider(client),
                        searchSuggestions: SearchSuggestionsDataProvider(client),
                    }}
                    notificationProvider={notificationProvider}
                    Layout={Layout}
                    ReadyPage={ReadyPage}
                    Sider={Sider}
                    Title={Title}
                    catchAll={<ErrorComponent />}
                    routerProvider={routerProvider}
                    authProvider={authProvider(client)}
                    LoginPage={LoginPage}
                    resources={[
                        {
                            name: 'categories',
                            list: CategoriesList,
                            show: CategoriesShow,
                            create: CategoryCreate,
                            edit: CategoryEdit,
                            icon: <CategoryOutlined />,
                        },
                        {
                            name: 'users',
                            list: UserList,
                            create: UserCreate,
                            edit: UserEdit,
                            icon: <PersonOutline />,
                        },
                        {
                            name: 'blogs',
                            list: BlogList,
                            create: BlogCreate,
                            edit: BlogEdit,
                        },
                        {
                            name: 'banners',
                            list: BannerList,
                            create: BannerCreate,
                            edit: BannerEdit,
                        },
                        {
                            name: 'configs',
                            list: ConfigList,
                            // create: BannerCreate,
                            edit: ConfigEdit,
                        },
                        {
                            name: 'searchSuggestions',
                            list: SearchSuggestionList,
                            create: SearchSuggestionCreate,
                            edit: SearchSuggestionEdit,
                        },
                        {
                            name: 'shops',
                            list: ShopList,
                            edit: ShopEdit,
                            create: ShopCreate,
                            icon: <StorefrontOutlined />,
                        },
                        {
                            name: 'shopAttributes',
                        },
                        {
                            name: 'socialMedias',
                            list: SocialMediaList,
                            parentName: 'shopAttributes',
                            icon: <ThumbUpOutlined />,
                        },
                        {
                            name: 'authenticationTypes',
                            list: AuthenticationTypeList,
                            parentName: 'shopAttributes',
                            icon: <ScienceOutlined />,
                        },
                        {
                            name: 'deliveryOptions',
                            list: DeliveryOptionList,
                            parentName: 'shopAttributes',
                            icon: <ShoppingBagOutlined />,
                        },
                        {
                            name: 'deliveryServiceProviders',
                            list: DeliveryServiceProviderList,
                            parentName: 'shopAttributes',
                            icon: <LocalShippingOutlined />,
                        },
                    ]}
                />
            </RefineSnackbarProvider>
        </ThemeProvider>
    )
}

export default App
