import { GraphQLClient } from 'graphql-request'
import { DataProvider } from '@pankod/refine-core'
import { GetConfigsDocument, GetConfigDocument, UpdateConfigInput, UpdateConfigDocument } from '../../gql/graphql'
// import { UpdateConfigInput } from 'gql/graphql'

export const ConfigsDataProvider = (client: GraphQLClient): DataProvider =>
    ({
        getList: async ({ pagination }) => {
            const result = await client.request(GetConfigsDocument)
            return {
                data: result.adminConfigs.items,
                total: result.adminConfigs.total,
            }
        },
        async getOne({ id }: { id: string }) {
            const result = await client.request(GetConfigDocument, { id })
            return { data: result.adminConfig }
        },
        async update({ variables, id }: { variables: UpdateConfigInput; id: string }) {
            const { value } = variables

            const result = await client.request(UpdateConfigDocument, { id, input: { value } })
            return {
                data: result.adminConfigUpdate,
            }
        },
    } as DataProvider)
