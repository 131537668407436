import { createTheme, PaletteColorOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        subtitle3: React.CSSProperties
        smallSubtitle: React.CSSProperties
        smallButton: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        subtitle3?: React.CSSProperties
        smallSubtitle?: React.CSSProperties
        smallButton?: React.CSSProperties
    }

    interface Palette {
        gold: string
        rose: string
    }
    interface PaletteOptions {
        gold: PaletteColorOptions
        rose: PaletteColorOptions
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        gold: true
    }
}

declare module '@mui/material/Icon' {
    interface IconPropsColorOverrides {
        gold: true
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        subtitle3: true
        smallSubtitle: true
        smallButton: true
    }
}
const { palette } = createTheme()
const { augmentColor } = palette
export const themeOptions = createTheme({
    palette: {
        primary: {
            main: '#f18a6d',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#000000', // '#E38F73',
            contrastText: '#ffffff',
        },
        divider: '#8F8F8F',
        gold: augmentColor({
            color: {
                main: '#E9BB6D',
            },
        }),
        rose: augmentColor({
            color: {
                main: '#F1E4DB',
            },
        }),
        grey: {
            100: '#8F8F8F',
        },
    },
    components: {
        MuiIcon: {
            defaultProps: {
                baseClassName: 'material-symbols-outlined',
            },
        },
    },
})
