import React from 'react'
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    Stack,
    EditButton,
    ShowButton,
    List,
    TextFieldComponent,
} from '@pankod/refine-mui'

import { DeliveryServiceProvider } from 'gql/graphql'

const columns: GridColumns<DeliveryServiceProvider> = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.name.en} />
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    {/* <ShowButton hideText recordItemId={params.row.id} /> */}
                    {/* <EditButton hideText recordItemId={params.row.id} /> */}
                </Stack>
            )
        },
    },
]

export const DeliveryServiceProviderList: React.FC = () => {
    const { dataGridProps } = useDataGrid<DeliveryServiceProvider>({
        dataProviderName: 'deliveryServiceProviders',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
