import React from 'react'
import {
    useDataGrid,
    DataGrid,
    GridColumns,
    Stack,
    EditButton,
    ShowButton,
    UrlField,
    List,
    TextFieldComponent,
} from '@pankod/refine-mui'

import { Banner } from 'gql/graphql'
import { DateTime } from 'luxon'

const columns: GridColumns<Banner> = [
    {
        field: 'image',
        headerName: 'Banner',
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
            return <img src={params.row.image.en} width="40" />
        },
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.name.en} />
        },
    },
    {
        field: 'link',
        headerName: 'Link',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <UrlField target="_blank" value={params.row.link ?? ''}>
                    {'Link'}
                </UrlField>
            )
        },
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <TextFieldComponent
                    value={params.row.startDate ? DateTime.fromISO(params.row.startDate).toFormat('fff') : ''}
                />
            )
        },
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return (
                <TextFieldComponent
                    value={params.row.endDate ? DateTime.fromISO(params.row.endDate).toFormat('fff') : ''}
                />
            )
        },
    },
    {
        field: 'sortIndex',
        headerName: 'Sort Index',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.sortIndex} />
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    {/* <ShowButton hideText recordItemId={params.row.id} /> */}
                    <EditButton hideText recordItemId={params.row.id} />
                </Stack>
            )
        },
    },
]

export const BannerList: React.FC = () => {
    const { dataGridProps } = useDataGrid<Banner>({
        dataProviderName: 'banners',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
