import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { ShopSocialMedia, SocialMedia } from 'gql/graphql'
import { Control, Controller, ControllerRenderProps, useFieldArray } from '@pankod/refine-react-hook-form'
import { Autocomplete, Box, Button, MuiTextFieldProps, Typography, useAutocomplete } from '@pankod/refine-mui'
import { useEffect, useState } from 'react'
export const ShopSocialMediaDynamicForm = ({
    control,
    name = 'socialMedias',
}: {
    control: Control<any>
    name?: string
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socialMedias',
        keyName: 'code',
    })

    const { autocompleteProps: socialMediaAutocompleteProps } = useAutocomplete({
        resource: 'socialMedias',
        dataProviderName: 'socialMedias',
    })
    const [socialMedias, setSocialMedias] = useState<{
        [key: string]: SocialMedia | undefined | null
    }>({})

    useEffect(() => {
        console.log(socialMedias)
    }, [socialMedias])
    return (
        <Box
            sx={{
                mt: 2,
            }}
        >
            <Typography variant="h6">Social Media</Typography>
            {fields.length === 0 && (
                <Button
                    variant="outlined"
                    sx={{
                        mt: 2,
                    }}
                    onClick={() => {
                        append({})
                    }}
                >
                    Add Social Media
                </Button>
            )}

            {fields.map((item, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                    >
                        <Controller
                            name={`socialMedias.${index}.id`}
                            control={control}
                            defaultValue={(item as unknown as ShopSocialMedia).id ?? ''}
                            render={({ field }) => {
                                return <input type="hidden" {...field} />
                            }}
                        />
                        <Controller
                            name={`socialMedias.${index}.socialMedia`}
                            control={control}
                            defaultValue={(item as unknown as ShopSocialMedia).socialMedia}
                            render={({ field }) => {
                                const value = field.value

                                return (
                                    <Autocomplete
                                        {...socialMediaAutocompleteProps}
                                        {...field}
                                        sx={{
                                            flexBasis: '30%',
                                        }}
                                        value={value || null}
                                        onChange={(_, value) => {
                                            field.onChange(value)
                                            setSocialMedias((prev) => {
                                                return {
                                                    ...prev,
                                                    [index]: value,
                                                }
                                            })
                                        }}
                                        getOptionLabel={(item) => {
                                            return (
                                                socialMediaAutocompleteProps?.options?.find(
                                                    (p) => p.id.toString() === item.id,
                                                )?.name?.en || ''
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return value === undefined || option.id === value.id
                                        }}
                                        renderInput={(params: JSX.IntrinsicAttributes & MuiTextFieldProps) => (
                                            <TextField
                                                {...params}
                                                label="Social Media"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name={`socialMedias.${index}.link`}
                            control={control}
                            defaultValue={(item as unknown as ShopSocialMedia).link}
                            render={({ field }) => {
                                return <CustomTextField field={field} index={index} socialMedias={socialMedias} />
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton onClick={() => remove(index)}>
                                <RemoveIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    append({})
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

const CustomTextField = ({
    field,
    socialMedias,
    index,
}: {
    field: ControllerRenderProps<any, `socialMedias.${number}.link`>
    index: number
    socialMedias: {
        [key: string]: SocialMedia | undefined | null
    }
}) => {
    const [label, setLabel] = useState('Link/ID/Tel')

    useEffect(() => {
        const ss = socialMedias?.[index]
        if (socialMedias && ss && ss !== null && ss !== undefined) {
            console.log('useEffect', ss)
            setLabel(ss.label?.en || 'Link/ID/Tel')
        } else {
            setLabel('Link/ID/Tel')
        }
    }, [socialMedias])

    return <TextField {...field} label={label} variant="outlined" margin="normal" fullWidth />
}
