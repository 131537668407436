import React from 'react'
import { useDataGrid, DataGrid, GridColumns, Stack, EditButton, List, TextFieldComponent } from '@pankod/refine-mui'

import { Blog } from 'gql/graphql'

const columns: GridColumns<Blog> = [
    {
        field: 'featureImage',
        headerName: 'Feature Image',
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
            return <img src={params.row.featureImage} width="150" />
        },
    },
    {
        field: 'slug',
        headerName: 'Slug',
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.slug} />
        },
    },
    {
        field: 'author',
        headerName: 'Author',
        flex: 1,
        minWidth: 100,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.author} />
        },
    },
    {
        field: 'publishedAt',
        headerName: 'publishedAt',
        flex: 1,
        minWidth: 350,
        renderCell: function render(params) {
            return <TextFieldComponent value={params.row.publishedAt} />
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        minWidth: 250,
        renderCell: function render(params) {
            return (
                <Stack direction="row" spacing={1}>
                    {/* <ShowButton hideText recordItemId={params.row.id} /> */}
                    <EditButton hideText recordItemId={params.row.id} />
                </Stack>
            )
        },
    },
]

export const BlogList: React.FC = () => {
    const { dataGridProps } = useDataGrid<Blog>({
        dataProviderName: 'blogs',
    })

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} autoHeight />
        </List>
    )
}
