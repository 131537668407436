import { HttpError } from '@pankod/refine-core'
import { Edit, Box, TextField, FormControlLabel, Checkbox } from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { MultilingualTextField } from 'components/MultilingualTextField'
import { UpdateBannerInput, Banner } from 'gql/graphql'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { ImageInput } from 'components/ImageInput'
export const BannerEdit: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
        control,
    } = useForm<Banner, HttpError, UpdateBannerInput>({
        refineCoreProps: {
            dataProviderName: 'banners',
        },
    })
    const [setted, setSetted] = useState(false)
    const [startDate, setStartDate] = useState<DateTime | null>(DateTime.now())
    const [endDate, setEndDate] = useState<DateTime | null>(null)

    useEffect(() => {
        if (!setted) {
            setStartDate(
                queryResult?.data?.data?.startDate ? DateTime.fromISO(queryResult?.data?.data?.startDate) : null,
            )
            setEndDate(queryResult?.data?.data?.endDate ? DateTime.fromISO(queryResult?.data?.data?.endDate) : null)
            setSetted(true)
        }
    }, [formLoading])

    useEffect(() => {
        console.log('startDate', startDate)
        console.log('endDate', endDate)
    }, [startDate, endDate])

    return (
        <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="name"
                    defaultValue=" "
                    label="Name"
                />
                <FormControlLabel
                    control={
                        <Controller
                            name="showButton"
                            render={({ field }) => {
                                return <Checkbox checked={field.value ?? false} {...field} />
                            }}
                            control={control}
                        />
                    }
                    label={'Show Button?'}
                />
                <TextField
                    error={!!errors?.link}
                    helperText={errors.link?.message}
                    {...register('link')}
                    margin="normal"
                    defaultValue=" "
                    required
                    fullWidth
                    id="link"
                    label="Link"
                />
                <MultilingualTextField
                    errors={errors}
                    register={register}
                    margin="normal"
                    fullWidth
                    name="buttonLabel"
                    defaultValue=" "
                    label="Button Text"
                />
                <input type="hidden" value="en" {...register('images.0.language')} />
                <ImageInput
                    name="images.0.file"
                    control={control}
                    label="English version Image"
                    existImage={queryResult?.data?.data?.image?.en ?? undefined}
                />
                <input type="hidden" value="zhHant" {...register('images.1.language')} />
                <ImageInput
                    name="images.1.file"
                    control={control}
                    label="Chinese version Image"
                    existImage={queryResult?.data?.data?.image?.zhHant ?? undefined}
                />
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="Start Date"
                                inputFormat="yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
                                value={value}
                                onChange={(event) => {
                                    onChange(event)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={!!error} helperText={error?.message} />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                <br />
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                label="End Date"
                                inputFormat="yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
                                value={value}
                                onChange={(event) => {
                                    onChange(event)
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} error={!!error} helperText={error?.message} />
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                <TextField
                    error={!!errors?.sortIndex}
                    helperText={errors.sortIndex?.message}
                    {...register('sortIndex')}
                    margin="normal"
                    fullWidth
                    id="sortIndex"
                    label="Sort Index"
                    name="sortIndex"
                    defaultValue=""
                    type="number"
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
        </Edit>
    )
}
