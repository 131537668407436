import { GetOneResponse, QueryObserverResult } from '@pankod/refine-core'
import { Box, Button, Typography } from '@pankod/refine-mui'
import { Controller } from '@pankod/refine-react-hook-form'
import { BACKEND_URL } from 'config'
import { Shop, ShopImageType } from 'gql/graphql'
import { useEffect, useState } from 'react'

export interface LogoInputProps {
    control: any
    formLoading: boolean
    queryResult: QueryObserverResult<GetOneResponse<Shop>, unknown> | undefined
}

export const LogoInput = ({ control, queryResult, formLoading }: LogoInputProps) => {
    const [logo, setLogo] = useState<string | undefined>(undefined)
    const [id, setId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (queryResult?.data?.data?.images) {
            const logoImage = queryResult.data.data.images.find((image) => image.type === ShopImageType.Logo)
            if (logoImage) {
                setLogo(`${BACKEND_URL}${logoImage.url}`)
                setId(logoImage.id)
            }
        }
    }, [formLoading])

    return (
        <>
            <Typography variant="h6">Logo</Typography>
            {logo && <img src={logo} alt="logo" style={{ width: '200px', height: 'auto', marginBottom: 10 }} />}
            <Box display="flex" flexDirection="row" gap={2}>
                <Controller
                    control={control}
                    name="logoImage"
                    defaultValue={null as any}
                    render={({ field }) => {
                        const { value, onChange, ...rest } = field
                        return (
                            <Button variant="contained" component="label" fullWidth style={{ marginBottom: 10 }}>
                                {logo ? 'Upload another image' : 'Upload Logo'}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            if (e.target.files[0].size > 2000000) {
                                                alert('File size must be less than 2mb')
                                                return
                                            }
                                            onChange(e.target.files[0])
                                            const objectUrl = URL.createObjectURL(e.target.files[0])
                                            setLogo(objectUrl)
                                        }
                                    }}
                                    {...rest}
                                />
                            </Button>
                        )
                    }}
                />
                {logo && (
                    <Controller
                        control={control}
                        name={`removeImages.${id}`}
                        defaultValue={false}
                        render={({ field }) => {
                            const { value, onChange, ...rest } = field
                            return (
                                <Button
                                    variant="contained"
                                    color="error"
                                    fullWidth
                                    style={{ marginBottom: 10 }}
                                    onClick={() => {
                                        onChange(true)
                                        setLogo(undefined)
                                    }}
                                >
                                    Remove Logo
                                </Button>
                            )
                        }}
                    />
                )}
            </Box>
        </>
    )
}
