import { HttpError } from '@pankod/refine-core'
import {
    Create,
    Box,
    TextField,
    Autocomplete,
    Icon,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@pankod/refine-mui'
import { Controller, useForm } from '@pankod/refine-react-hook-form'
import { User, CreateUserInput, UserStatus } from 'gql/graphql'
import { CountryCodeList } from './country-code-list'

export const UserCreate: React.FC = () => {
    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        formState: { errors },
        register,
        control,
    } = useForm<User, HttpError, CreateUserInput>({
        refineCoreProps: {
            dataProviderName: 'users',
        },
    })
    const userStatus = [
        { value: 'ACTIVE', label: 'Active' },
        { value: 'INACTIVE', label: 'Inactive' },
        { value: 'UNVERIFIED', label: 'Unverified' },
    ]
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
            <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
                <Controller
                    control={control}
                    name="countryCode"
                    defaultValue={'852'}
                    render={({ field: { onChange, value, ...rest } }) => {
                        const newValue = value as unknown as {
                            value: string
                            label: string
                        }
                        return (
                            <FormControl {...rest} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Country Code</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={newValue}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    label="Country Code"
                                >
                                    {CountryCodeList.map((item, k) => (
                                        <MenuItem key={k} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )
                    }}
                />
                <TextField
                    error={!!errors?.phone}
                    helperText={errors.phone?.message}
                    {...register('phone', { required: 'Phone Number is required' })}
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                />
                <Controller
                    control={control}
                    name="status"
                    defaultValue={UserStatus.Active}
                    render={({ field: { onChange, value, ...rest } }) => {
                        const newValue = value as unknown as {
                            value: string
                            label: string
                        }
                        return (
                            <FormControl {...rest} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">User Status</InputLabel>
                                <Select
                                    value={newValue}
                                    onChange={(e) => {
                                        onChange(e.target.value)
                                    }}
                                    label="User Status"
                                >
                                    {userStatus.map((item, k) => (
                                        <MenuItem key={k} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )
                    }}
                />
            </Box>
        </Create>
    )
}
